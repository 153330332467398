<template>
    <div class="d-flex justify-content-center align-items-center">
        <div class="spinner-border text-dark" role="status"></div>
    </div>
</template>

<script>
export default {
    name: 'Loader',
}
</script>