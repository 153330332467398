<template>
    <admin-layout>
        <visible-for role="admin">
            <h1 class="fw-bold text-center mb-3">Kurzy</h1>
            <div class="text-center mb-4">
                <router-link :to="{name: 'courses.create'}" class="btn btn-success fw-bold px-3">Pridať kurz</router-link>
            </div>
            <loader v-if="fetching"></loader>
            <div v-else class="table-responsive">
                <table class="table table-bordered table-hover bg-white">
                    <thead class="align-middle table-dark">
                        <tr class="fw-bold">
                            <th scope="col">Názov</th>
                            <th scope="col">Popis</th>
                            <th class="text-center">Jazyk</th>
                            <th class="text-center" scope="col">Počet videí</th>
                            <th class="text-center" scope="col">Akcia</th>
                        </tr>
                    </thead>
                    <tbody>
                        <course-list-table-row v-for="course in courses" :key="course.id" :course="course"></course-list-table-row>
                    </tbody>
                </table>
            </div>
        </visible-for>
    </admin-layout>
</template>

<script>
import AdminLayout from '../layout/AdminLayout'
import VisibleFor from '../component/VisibleFor'
import CourseListTableRow from '../component/CourseListTableRow'
import Loader from '../component/Loader'
import { isEmpty } from 'ramda'
import { EventBus } from '@/main'

export default {
    beforeDestroy () {
        EventBus.$off('locale-changed', this.fetchCourses)
    },
    components: { Loader, CourseListTableRow, VisibleFor, AdminLayout },
    computed: {
        courses () {
            return this.$store.getters['course/selectCourses']
        },
        fetching () {
            return (this.$store.getters['course/isFetching'] && isEmpty(this.courses))
        },
    },
    methods: {
        fetchCourses () {
            this.$store.dispatch('course/fetchCourses')
        },
    },
    mounted () {
        this.fetchCourses()
        
        EventBus.$on('locale-changed', this.fetchCourses)
    },
    name: 'CourseList',
}
</script>

<style lang="scss" scoped>
table {
    tr {
        td, th {
            &:last-child {
                width: 200px;
            }
        }
    }
}
</style>
