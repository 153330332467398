<template>
    <tr class="align-middle">
        <td>{{ course.title }}</td>
        <td>
            <p v-if="!! course.description" class="text-truncate m-0" v-html="course.description"></p>
        </td>
        <td class="text-center">{{ course.language.toUpperCase() }}</td>
        <td class="text-center">{{ videosCount }}</td>
        <td>
            <div class="d-flex justify-content-center align-items-center">
                <router-link :to="{name: 'courses.detail', params: {courseId: course.id}}" class="btn btn-success mx-1" title="Zobraziť detail">
                    <i class="bi bi-eye-fill"></i>
                </router-link>
                <router-link :to="{name: 'courses.update', params: {courseId: course.id}}" class="btn btn-warning mx-1" title="Upraviť kurz">
                    <i class="bi bi-pencil-fill"></i>
                </router-link>
                <button class="btn btn-danger mx-1" title="Vymazať kurz" type="button" @click.prevent="deleteCourse">
                    <i class="bi-trash-fill"></i>
                </button>
            </div>
        </td>
    </tr>
</template>

<script>
import { length } from 'ramda'

export default {
    computed: {
        videosCount () {
            return length(this.course.videos)
        }
    },
    methods: {
        deleteCourse () {
            if (confirm(`Naozaj chcete vymazať kurz ${this.course.title}?`)) {
                this.$store.dispatch('course/deleteCourse', this.course.id)
            }
        },
    },
    name: 'CourseListTableRow',
    props: {
        course: Object
    },
}
</script>

<style lang="scss" scoped>
.btn {
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px;
}

.text-truncate {
    max-width: 500px;
}
</style>
